import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import './Offboard.scss';

function ExtOffboard() {
    
    const { c } = useParams();

    useEffect(() => {
        if (c && fetch) {
            fetch("https://e.lingolayer.com/p", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({e: 'uninstall', c})
            }).catch(() => {});
        }
    }, [c]);

    return (
        <div>
            <div id="hero">
                <h2 id="tagline">We're sorry to to see you go..</h2>
            </div>

            <main>
                <section>
                    <h3 className="mt-5">Your feedback is valuable!</h3>
                    <p className="text">
                        Please visit our <Link to="/support">Help &amp; Support</Link> page to share your comments.
                    </p>
                </section>
            </main>
        </div>
    );
}

export default ExtOffboard;