import { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faCloudArrowUp, faTrash, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import './Support.scss';

function ContactForm({ contactFormManager }) {
    const [formState, setFormState] = useState(contactFormManager.getState());
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [attachments, setAttachents] = useState([]);
    const [errors, setErrors] = useState({});

    const maxMb = 20;
    const maxSize = maxMb * 1024 * 1024;
    const maxFiles = 10;

    const onDrop = useCallback(acceptedFiles => {
        setAttachents([...attachments, ...acceptedFiles]);
    }, [attachments]);

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles,
        maxSize,
        onDrop
    });

    const removeAttachment = (file) => {
        const newAttachments = attachments.filter(f => f !== file)
        setAttachents(newAttachments);
    }

    // console.log('formState', formState);

    const resetForm = () => {
        setEmail("");
        setPhone("");
        setName("");
        setSubject("");
        setMessage("");
        setAttachents([]);
        setErrors({});
        contactFormManager.reset();
    }

    const submitForm = async (e) => {
        // console.log('submitForm', formState);

        if (formState !== contactFormManager.STATES.IDLE) {
            return;
        }

        // const formInstanceId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        const errors = {};

        if (!email) {
            errors['email'] = "Please enter your email address.";
        } else if (!/^[^@]+@[^@]+\.[^@.]{2,}$/.test(email)) {
            errors['email'] = "Please enter a valid email address.";
        }
        if (!subject.trim()) {
            errors['subject'] = "Please enter a brief summary of your issue.";
        }
        if (!message.trim()) {
            errors['message'] = "Please describe your issue details.";
        }
        setErrors(errors);
        // console.log(errors);

        if (!Object.keys(errors).length) {
            // // we need to allow the user to re-click the button in case the captcha is not invisible
            // // and they cancel the captcha and need to try again
            // // wait for the captcha to execute
            // const recaptchaResult = await contactFormManager.executeCaptcha(formInstanceId);

            // // console.log('recaptchaResult', recaptchaResult);

            // if (recaptchaResult?.formInstanceId !== formInstanceId) {
            //     // this can happen if the user clicks on submit multiple times but doesn't complete the captcha
            //     // when the final captcha is finally completed we'll get the callback but it'll be STALE
            //     return;
            // }

            // const { recaptchaToken } = recaptchaResult;
            // if (recaptchaToken) {
                // await contactFormManager.submit({ name, email, phone, subject, message, attachments, recaptchaToken });
                await contactFormManager.submit({ name, email, phone, subject, message, attachments });
                if (contactFormManager.getState() === contactFormManager.STATES.ERROR) {
                    setErrors({ general: "We encountered an error sending your message. Please try again." });
                    contactFormManager.reset();
                }
            // } else {
            //     setErrors({ general: "We encountered an error with captcha. Please try again." });
            //     contactFormManager.reset();
            // }
        } else {
            contactFormManager.setState(contactFormManager.STATES.IDLE);
        }
    }

    useEffect(() => {
        const onStateChange = ({ state }) => {
            // console.log('onStateChange', state);
            setFormState(state);
        }

        contactFormManager.addEventListener('stateChanged', onStateChange);

        return () => {
            contactFormManager.removeEventListener('stateChanged', onStateChange);
        }
    }, [contactFormManager]);

    useLayoutEffect(() => {
        if (formState === contactFormManager.STATES.IDLE) {
            // honeypot setup
            const input = document.querySelector('#contactForm .phone input');
            if (input) {
                input.setAttribute('tabindex', '-1');
                input.setAttribute('autocomplete', 'off');
                input.setAttribute('disabled', true);
            }
        }
    }, [contactFormManager, formState]);


    switch (formState) {
        case contactFormManager.STATES.IDLE:
            return (
                <div id="contactForm">
                    <div className="mb-3 email">
                        <label htmlFor="email" className="form-label">Email: <span className="text-danger">*</span></label>
                        <input type="email" id="email" name="email" className="form-control" value={email || ''} onChange={(e) => setEmail(e.target.value.trim())} required={true} />
                        {errors.email ? <div className="small text-danger">{errors.email}</div> : null}
                    </div>
                    {/* Honeypot */}
                    <div className="mb-3 phone">
                        <label htmlFor="phone" className="form-label">Phone: <span className="text-danger">*</span></label>
                        <input type="text" id="phone" name="phone" className="form-control" value={phone || ''} onChange={(e) => setPhone(e.target.value)} required={true} />
                        {errors.phone ? <div className="small text-danger">{errors.phone}</div> : null}
                    </div>
                    <div className="mb-3 name">
                        <label htmlFor="name" className="form-label">Name:</label>
                        <input type="text" id="name" name="name" className="form-control" value={name || ''} onChange={(e) => setName(e.target.value)} />
                        {errors.name ? <div className="small text-danger">{errors.name}</div> : null}
                    </div>
                    <div className="mb-3 subject">
                        <label htmlFor="subject" className="form-label">Summary: <span className="text-danger">*</span></label>
                        <input type="text" id="subject" name="subject" className="form-control" value={subject || ''} onChange={(e) => setSubject(e.target.value)} required={true} />
                        {errors.subject ? <div className="small text-danger">{errors.subject}</div> : null}
                    </div>
                    <div className="mb-3 message">
                        <label htmlFor="message" className="form-label">Details: <span className="text-danger">*</span></label>
                        <textarea id="message" name="message" className="form-control" value={message || ''} onChange={(e) => setMessage(e.target.value)} required={true}></textarea>
                        {errors.message ? <div className="small text-danger">{errors.message}</div> : null}
                    </div>
                    <div className="mb-3 files">
                        <label htmlFor="files" className="form-label">Attachments:</label>
                        <div {...getRootProps({ className: 'dropzone p-2' })}>
                            <div className="d-flex flex-column align-items-center justify-content-center text-center small">
                                <input {...getInputProps()} />
                                <div>Drag files here, or click to select files.</div>
                                <div className="mt-1"><FontAwesomeIcon icon={faCloudArrowUp} size="3x" className="text-muted" /> </div>
                                <div className="mt-1">{`(Maximum ${maxFiles} files, up to ${maxMb}MB each)`}</div>
                            </div>
                            {attachments.length ? <div className="small p-2 pb-0">
                                <hr className="mt-1 mb-2" />
                                <div className="fw-bold">Attached files: {attachments.length} / {maxFiles} maximum</div>
                                {attachments.map(file => (
                                    <div key={file.path}>
                                        <FontAwesomeIcon icon={faCircleCheck} size="sm" className="text-success" /> {file.path} ({file.size} bytes)
                                        <span className="ms-2" role="button" onClick={(e) => {removeAttachment(file); e.stopPropagation();}}><FontAwesomeIcon icon={faTrash} size="sm" className="text-danger" /></span>
                                    </div>
                                ))}
                            </div> : null}
                        </div>
                        {errors.attachments ? <div className="small text-danger">{errors.attachments}</div> : null}
                    </div>
                    <hr />
                    {errors.general ? <div className="mb-3 text-danger text-center"><FontAwesomeIcon icon={faCircleExclamation} size="sm" className="text-danger" /> {errors.general}</div> : null}
                    <div className="mb-3 d-flex justify-content-between align-items-start">
                        <div className="small">
                            {/* This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply. */}
                        </div>
                        <button className="btn btn-primary" onClick={submitForm}>Send Message</button>
                    </div>
                </div>
            )
        // case contactFormManager.STATES.CAPTCHA_COMPLETE:
        case contactFormManager.STATES.SUBMITTING:
            return <div className="d-flex flex-row align-items-center">
                <div className="spinner-border spinner-border-sm me-2" />
                <div>Sending...</div>
            </div>;
        case contactFormManager.STATES.SUCCESS:
            return <div>
                <div><FontAwesomeIcon icon={faCircleCheck} className="text-success" /> Thank you for your message. Someone will get back to you shortly.</div>
                <div className="mt-3"><button className="btn btn-primary btn-sm" onClick={resetForm}>Send Another Message</button></div>
            </div>;
        case contactFormManager.STATES.UNLOADED:
        case contactFormManager.STATES.INITIALIZING:
        default:
            return <div className="d-flex flex-row align-items-center">
                <div className="spinner-border spinner-border-sm me-2" />
                <div>Please wait while the form loads...</div>
            </div>;
    }
}

function Support({ contactFormManager }) {

    useEffect(() => {
        contactFormManager.init();
    }, [contactFormManager]);

    return (
        <div>
            <div id="hero">
                <h2 id="tagline">Help & Support</h2>
            </div>

            <main>
                {/* <div id="existing-tickets">
          <section>
            <h2 className="mb-3">Existing Ticket?</h2>
            <p className="mb-0">
              If you have already submitted a support ticket you may manage it in the <a href="https://lingolayer.atlassian.net/servicedesk/customer/portal/1" target="_blank" rel="noreferrer">Support Portal <FontAwesomeIcon icon={faUpRightFromSquare} /></a>.
            </p>
          </section>
        </div> */}
                <section>
                    <div className="mt-4">
                        <Link to="/">
                            <FontAwesomeIcon icon={faAngleLeft} size="sm" className="me-1" />
                            Home
                        </Link>
                    </div>
                </section>

                <div id="new-ticket">
                    <section>
                        <p>
                            Please reach out if you have any questions or issues with Lingo Layer. You may also use this form to submit feedback and feature requests.
                        </p>
                        <hr />
                        <ContactForm contactFormManager={contactFormManager} />
                    </section>
                </div>
            </main>
        </div>
    );
}

export default Support;
