import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

import './Onboard.scss';

function ExtOnboard() {
    return (
        <div>
            <div id="hero">
                <h2 id="tagline">Get Started with Lingo Layer</h2>
            </div>

            <div className="text-center mt-3 pb-3 whats-ll">
                <Link to="/">What is Lingo Layer?</Link>
            </div>
            
            <main>
                <section>
                    <h3 className="mt-5">1. Go to Netflix</h3>
                    <p className="text">
                        Go to <a href="https://www.netflix.com/" target="_blank" rel="noreferrer">Netflix <FontAwesomeIcon icon={faExternalLink} size="sm" /></a> and the Lingo Layer sidebar will appear.
                    </p>
                    <div className="mb-2">
                        <img src="/images/onboarding/netflix-home.png" alt="Lingo Layer on Netflix" />
                    </div>
                    
                    <h3 className="mt-5">2. Open Language Settings</h3>
                    <p className="text">
                        Click on <em>View Settings</em> or the settings gear icon to display your <em>Language Settings</em>.
                    </p>
                    <div className="mb-2">
                        <img src="/images/onboarding/click-to-settings.png" alt="Language Settings" />
                    </div>
                    
                    <h3 className="mt-5">3. Choose your Languages</h3>
                    <p className="text">
                        Choose your <em>Learning Language</em> & <em>Fluent Languages</em>. You may also choose colors for each language. Changes are saved automatically.
                    </p>
                    <div className="mb-2">
                        <img src="/images/onboarding/choose-languages.png" alt="Choose Languages" />
                    </div>
                    
                    <h3 className="mt-5">4. Watch &amp; Learn</h3>
                    <p>
                        Click on a video to get started. Choose a video that has subtitles available in your languages.
                    </p>
                    <div className="mb-5">
                        <img src="/images/onboarding/watch-video.png" alt="Watch Video" />
                    </div>
                </section>
            </main>

            <div className="lingo-layer-home p-4">
                <p>Want to learn more about Lingo Layer?</p>
                <Link to="/" className="btn btn-warning" title="Lingo Layer">Lingo Layer Homepage</Link>
            </div>
        </div>
    );
}

export default ExtOnboard;