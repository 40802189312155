import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

// import HelpWidgetManager from './classes/HelpWidgetManager';
import ContactFormManager from './classes/ContactFormManager';

import Container from './templates/Container';
import Home from './pages/home/Home';
import Support from './pages/support/Support';
import Privacy from './pages/privacy/Privacy';
import ExtOnboard from './pages/ext/onboard/Onboard';
import ExtOffboard from './pages/ext/offboard/Offboard';

import './index.scss';

const { 
    // REACT_APP_RECAPTCHA_SITE_KEY,
    REACT_APP_FORM_TOKEN_URL,
    REACT_APP_FORM_SUBMIT_URL
} = process.env;

// const helpWidgetManager = new HelpWidgetManager({ displayFloatingButton: false });
// console.log(process.env);
const contactFormManager = new ContactFormManager({
    // recaptchaSiteKey: REACT_APP_RECAPTCHA_SITE_KEY, 
    // recaptchaContainer: document.getElementById('recaptchaContainer'),
    formTokenUrl: REACT_APP_FORM_TOKEN_URL,
    formSubmitUrl: REACT_APP_FORM_SUBMIT_URL
});

// displayHelpWidget will be false by default - we'll rely on a help navigation link to go to the help page
const router = createBrowserRouter([
    {
        path: "/",
        // element: <Container helpWidgetManager={helpWidgetManager}><Home helpWidgetManager={helpWidgetManager} /></Container>,
        element: <Container contactFormManager={contactFormManager}><Home contactFormManager={contactFormManager} /></Container>,
    },
    {
        path: "/support",
        // element: <Container helpWidgetManager={helpWidgetManager}><Support helpWidgetManager={helpWidgetManager} /></Container>,
        element: <Container contactFormManager={contactFormManager}><Support contactFormManager={contactFormManager} /></Container>,
    },
    {
        path: "/privacy",
        // element: <Container helpWidgetManager={helpWidgetManager}><Privacy helpWidgetManager={helpWidgetManager} /></Container>,
        element: <Container contactFormManager={contactFormManager}><Privacy contactFormManager={contactFormManager} /></Container>,
    },
    {
        path: "/ext/onboard",
        // element: <Container helpWidgetManager={helpWidgetManager}><ExtOnboard helpWidgetManager={helpWidgetManager} /></Container>,
        element: <Container contactFormManager={contactFormManager}><ExtOnboard contactFormManager={contactFormManager} /></Container>,
    },
    {
        path: "/ext/offboard/:c?",
        // element: <Container helpWidgetManager={helpWidgetManager}><ExtOffboard helpWidgetManager={helpWidgetManager} /></Container>,
        element: <Container contactFormManager={contactFormManager}><ExtOffboard contactFormManager={contactFormManager} /></Container>,
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));

if (
    // !REACT_APP_RECAPTCHA_SITE_KEY || 
    !REACT_APP_FORM_TOKEN_URL ||
    !REACT_APP_FORM_SUBMIT_URL
) {
    root.render(
        <React.StrictMode>
            <div>
                <p>Please set the following environment variables:</p>
                <ul>
                    {/* <li>REACT_APP_RECAPTCHA_SITE_KEY</li> */}
                    <li>REACT_APP_FORM_TOKEN_URL</li>
                    <li>REACT_APP_FORM_SUBMIT_URL</li>
                </ul>
            </div>
        </React.StrictMode>
    );
} else {
    root.render(
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
}