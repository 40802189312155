import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion, faCircleDown } from '@fortawesome/free-solid-svg-icons'

import './Container.scss';

function Container({ children }) {

  const [installed, setInstalled] = useState(false);

  // TODO: browser-specific logic
  const extensionId = "pkghojjmjelgjbjinopdlmilndlnhjbe";

  async function checkExtensionInstalled() {
    if (!window.chrome) {
      return false;
    }
    try {
      await fetch(`chrome-extension://${extensionId}/images/logo-128.png`);
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    (async () => {
      setInstalled(await checkExtensionInstalled());
    })();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [children]);

  return (
    <div className="App">
      <header>
        <nav className="navbar d-flex justify-items-between">
          <h1 id="logo" className="navbar-brand">
            <Link to="/" className="d-flex align-items-end" title="Lingo Layer">
              <img src="/images/logo3-transparent.png" alt="Lingo Layer Logo" width="40" />
              <span className="ms-2">Lingo Layer</span>
            </Link>
          </h1>
          <div className="d-flex align-items-center nav-links">
            <a href="https://chromewebstore.google.com/detail/lingo-layer-language-lear/pkghojjmjelgjbjinopdlmilndlnhjbe" className="me-3">
              <FontAwesomeIcon icon={faCircleDown} className="me-2" />
              {installed ? "Browser" : "Download"} Extension
            </a>
            <Link to="/support">
              <FontAwesomeIcon icon={faCircleQuestion} className="me-2" />
              Help
            </Link>
          </div>
        </nav>
      </header>

      <div className="Main">
        {children}
      </div>

      <footer>
        <div className="inner d-flex justify-content-between align-items-center">
          <p>&copy; 2023 Lingo Layer - All Rights Reserved. <Link to="/privacy">Privacy Policy</Link></p>
          <div>
            <a href="https://chromewebstore.google.com/detail/lingo-layer-language-lear/pkghojjmjelgjbjinopdlmilndlnhjbe" className="me-3">
              <FontAwesomeIcon icon={faCircleDown} className="me-1" />
              {installed ? "Browser" : "Download"} Extension
            </a>
            <Link to="/support">
              <FontAwesomeIcon icon={faCircleQuestion} className="me-1" />
              Help
            </Link>
          </div>
        </div>
      </footer>

      {/* <div id="jsd-widget-bg" onClick={helpWidgetManager.hideForm.bind(helpWidgetManager)}></div> */}
    </div>
  );
}

export default Container;