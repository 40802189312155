class ContactFormManager {
    // constructor({recaptchaSiteKey, recaptchaContainer, formSubmitUrl}) {
    constructor({formTokenUrl, formSubmitUrl}) {
        this.STATES = {
            UNLOADED: 'unloaded',
            INITIALIZING: 'initializing',
            LOAD_ERROR: 'load_error',
            IDLE: 'idle',
            // CAPTCHA_COMPLETE: 'captcha_complete',
            SUBMITTING: 'submitting',
            SUCCESS: 'success',
            ERROR: 'error',
        };

        // this.token = null;
        this.formToken = null;

        this.eventListeners = {};

        this.state = this.STATES.UNLOADED;
        // this.captchaWidgetId = null;
        // this.captchaRequiresReset = false;
        // this.recaptchaContainer = recaptchaContainer;
        // this.recaptchaSiteKey = recaptchaSiteKey;
        this.formTokenUrl = formTokenUrl;
        this.formSubmitUrl = formSubmitUrl;
    }

    async init() {
        this.setState(this.STATES.INITIALIZING);
        this.formToken = await this.getFormToken();
        if (this.formToken) {
            this.setState(this.STATES.IDLE);
        } else {
            this.setState(this.STATES.LOAD_ERROR);
        }
    }

    async getFormToken() {
        // build formData
        const formData = new FormData();
        formData.append('requestTime', Date.now());

        try {
            const response = await fetch(this.formTokenUrl, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                console.error("Error: server returned", response.status);
                return null;
            }
    
            const data = await response.json();
            return data.token;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    // async init() {
    //     // use a promise to wait up to 5 seconds for grecaptcha to be ready

    //     let maxWait = 5000;
    //     const interval = 100;
    //     // console.log('Waiting for grecaptcha...');
    //     while (!window.grecaptcha?.render && maxWait > 0) {
    //         await new Promise(resolve => setTimeout(resolve, interval));
    //         maxWait -= interval;
    //     }

    //     if (window.grecaptcha?.render) {
    //         // console.log('grecaptcha loaded');

    //         this.setState(this.STATES.IDLE);

    //         window.CONTACT_FORM_RECAPTCHA_CALLBACK = (token) => {
    //             this.token = token;
    //             this.setState(this.STATES.CAPTCHA_COMPLETE);
    //         };

    //         this.captchaWidgetId = window.grecaptcha.render(this.recaptchaContainer, {
    //             'sitekey': this.getRecaptchaSiteKey(),
    //             'size': 'invisible',
    //             'callback': 'CONTACT_FORM_RECAPTCHA_CALLBACK'
    //         });

    //     } else {
    //         this.setState(this.STATES.LOAD_ERROR);
    //     }
    // }

    // resetCaptcha() {
    //     if (!this.captchaRequiresReset)  {
    //         return;
    //     }
    //     if (this.state !== this.STATES.IDLE) {
    //         console.log('Cannot resetCaptcha, not in correct state');
    //         return;
    //     }
    //     if (this.captchaWidgetId === null) {
    //         console.log('Cannot resetCaptcha, no captcha widget id');
    //         return;
    //     }
    //     // timeout is needed to prevent weird error
    //     setTimeout(() => {
    //         window.grecaptcha.reset(this.captchaWidgetId);
    //     }, 500);
    //     this.captchaRequiresReset = false;
    // }

    // executeCaptcha(formInstanceId) {
    //     if (this.captchaWidgetId === null) {
    //         console.log('Cannot executeCaptcha, no captcha widget id');
    //         return {formInstanceId};
    //     }
    //     if (this.state !== this.STATES.IDLE)  {
    //         console.log('Cannot executeCaptcha, not in IDLE state');
    //         return {formInstanceId};
    //     }

    //     window.CONTACT_FORM_INSTANCE_ID = formInstanceId;

    //     const p = new Promise(resolve => {
    //         this.addEventListener('stateChanged', ({state}) => {
    //             if (state === this.STATES.CAPTCHA_COMPLETE) {
    //                 resolve({
    //                     formInstanceId: window.CONTACT_FORM_INSTANCE_ID,
    //                     recaptchaToken: this.getToken()
    //                 });
    //             }
    //         });
    //     });

    //     window.grecaptcha.execute(this.captchaWidgetId);
    //     this.captchaRequiresReset = true;
        
    //     return p;
    // }

    addEventListener(eventName, listener) {
        // console.log('addEventListener', eventName, listener);
        this.eventListeners[eventName] = this.eventListeners[eventName] || [];
        this.eventListeners[eventName].push(listener);
    }

    triggerEventListeners(eventName, data) {
        // console.log('triggerEventListeners', eventName, this.eventListeners[eventName]);
        (this.eventListeners[eventName]||[]).forEach(listener => {
            listener(data);
        });
    }

    removeEventListener(eventName, listener) {
        if (!this.eventListeners[eventName]) {
            return;
        }
        const index = this.eventListeners[eventName].indexOf(listener);
        if (index > -1) {
            this.eventListeners[eventName].splice(index, 1);
        }
    }

    getState() {
        return this.state;
    }

    setState(state) {
        this.state = state;
        this.triggerEventListeners('stateChanged', {state});
    }

    // getToken() {
    //     return this.token;
    // }

    reset() {
        this.init();
        // this.resetCaptcha();
    }

    // getRecaptchaSiteKey() {
    //     return this.recaptchaSiteKey;
    // }

    // async submit({name, email, phone, subject, message, attachments, recaptchaToken}) {
    async submit({name, email, phone, subject, message, attachments}) {
        this.setState(this.STATES.SUBMITTING);

        // build formData
        const formData = new FormData();
        formData.append('token', this.formToken);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('subject', subject);
        formData.append('message', message);
        // formData.append('recaptchaToken', recaptchaToken);

        for (let i = 0; i < (attachments||[]).length; i++) {
            formData.append(`attachments[${i}]`, attachments[i], attachments[i].name);
        }

        try {
            const response = await fetch(this.formSubmitUrl, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                console.error("Error: server returned", response.status);
                this.setState(this.STATES.ERROR);
                return;
            }
    
            const data = await response.json();
            // console.log(data);
            this.setState(this.STATES.SUCCESS);
        } catch (error) {
            console.error(error);
            this.setState(this.STATES.ERROR);
            return;
        }
    }
}

export default ContactFormManager;